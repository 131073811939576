import * as React from 'react'
import { Component } from 'react';
import { Form, Row, Col, Button, Input, Radio } from "antd-min";
import { SchoolPathConfig as PathConfig } from "../../../config/pathconfig";
import { lazyInject, TYPES } from '../../../util/index';
import { withRouter, GLForm, FormHelper, GLFormComponentProps, GLUtil, OidcProxy, GLGlobal, InvitationType, connect, StateType, MessageHelper, NotificationType, maskThrottle, unmaskThrottle } from "gl-commonui";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from 'react-router';
import { SchoolLocale } from '../../../locales/localeid';
import { GroupCard, GroupCards, RedeemCard } from '../../../components/group-card';
import { getInfoByCode } from '../../../states/school//inviting';
import { IInvitationService } from '../../../service/school/invitation/index';
import { bindById } from '../../../states/school//acceptinvitation';

const { renderFormItem } = FormHelper;
const RadioGroup = Radio.Group;

export interface InvitingProps extends GLFormComponentProps{
    isSignin?: boolean
    getInfoByCode: (param, callback) => void
    bindById: (param, callback) => void
}
interface InvitingStates {
    data?: any
}

const mapStateToProps = (state: StateType) => {
    return {
        isSignin: state.oidc.loginInfo && state.oidc.loginInfo.loggedin,
    }
}

@withRouter
@GLForm.create()
@connect(({oidc: { loginInfo }}: StateType) => ({
    isSignin: loginInfo && loginInfo.loggedin
}), {
    getInfoByCode,
    bindById
})
export class InvitingPage extends Component<InvitingProps & RouteComponentProps<any>, InvitingStates> {
    @lazyInject(TYPES.IInvitationService)
    service: IInvitationService
    code = GLUtil.queryParse().code
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    componentDidMount() {
        maskThrottle();
        this.props.getInfoByCode({ invitationCode: this.code }, (data) => this.setState({ data: data }, ()=> unmaskThrottle()));
    }

    renderHeaderContent(data) {
        if (!data) {
            return;
        }

        let id = SchoolLocale.InvitationSchoolAdminTitle;
        if (data.invitationType == InvitationType.accountManager) {
            id = SchoolLocale.InvitationAccountManagerTitle;
        } else if (data.invitationType == InvitationType.classTeacher || 
            data.invitationType == InvitationType.subTeacher || 
            data.invitationType == InvitationType.schoolTeacher) {
            id = SchoolLocale.InvitationTeacherTitle;
        } else if (data.invitationType == InvitationType.campusAdmin) {
            id = SchoolLocale.InvitationCampusAdminTitle;
        }
        return GLGlobal.intl.formatMessage({id: id}, { email: data.inviteeEmail, reference: data.referenceResourceName});
    }

    callback() {
        this.props.history.push({ pathname: PathConfig.Invited, search: GLUtil.queryStringify({ email: GLGlobal.loginInfo().profile.email, code: this.code }) });
    }

    bindingUser(data) {
        if (!this.props.isSignin) {
            MessageHelper.Message(NotificationType.Failed, SchoolLocale.LoginFirstTitle);
            return;
        }
        const params = {
            id: GLGlobal.loginInfo().profile.sub,
            invitationCodeType: data.invitationType,
            invitationCodeId: data.id
        }
        this.props.bindById(params, this.callback.bind(this));
    }

    useCurrentAccount() {
        this.props.getInfoByCode({ invitationCode: this.code }, (data) => this.bindingUser(data));
    }

    goToNext(text: string) {
        switch(text) {
            case "current":
                this.useCurrentAccount();
                break;
            case "existing":
                this.props.history.push({ pathname: PathConfig.RedeemExisting, search: GLUtil.queryStringify({ code: this.code })});
                break;
            case "register":
                this.props.history.push({ pathname: PathConfig.Register, search: GLUtil.queryStringify({ code: this.code })});
                break;
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {}
        });
    }
    
    getUseCurrentTextElement(email) {
        const invitationText = GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationCurrentText }, { email: email });
        const invitationTexts = invitationText.split(email);
        return (
            <React.Fragment>
                {invitationTexts[0]}
                <b>{email}</b>
                {invitationTexts[1]}
            </React.Fragment>
        )
    }

    render() {
        const form = this.props.form;
        const email = GLGlobal.loginInfo().profile && GLGlobal.loginInfo().profile.email;
        return <Row>
            <Row className='invitationsubject'>
                <FormattedMessage id={SchoolLocale.InvitationSubject} ></FormattedMessage>
            </Row>
            <Row className='invitationtitle'>{this.renderHeaderContent(this.state.data)}</Row>
            <Row className='selectuser'>
                {this.props.isSignin && <RedeemCard textElement={this.getUseCurrentTextElement(email)} contents={[GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationCurrentAccount })]} onClick={() => this.goToNext("current")}></RedeemCard>}
                <RedeemCard text={GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationExistingText })} contents={[GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationExistingAccount })]} onClick={() => this.goToNext("existing")}></RedeemCard>
                <RedeemCard text={GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationRegisterText })} contents={[GLGlobal.intl.formatMessage({ id: SchoolLocale.RegisterAccount })]} onClick={() => this.goToNext("register")}></RedeemCard>
            </Row>
        </Row>;
    }
}